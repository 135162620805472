import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'


import FeatureImg from './images/feature-img.svg'
import FeatureBg from './images/Rectangle 117.svg'

const Feature = () => {
    return (
        <div className="Feature ">
            <img src={FeatureBg} alt="Feature bg" className="Featurebg  " />
            <p className="feature-heading text-center">Creators <span>are powerful</span></p>
            <Container >
                <Row>
                    <Col lg={5} className="d-sm-flex align-items-center justify-content-center">
                        <img src={FeatureImg} alt="FeatureImg" className="feature-img" />
                    </Col>
                    <Col className="d-sm-flex flex-column align-items-center justify-content-center ">
                        <div>
                            <p className="text-md-end  mt-5 mt-md-0 feature-top-heading ">CREATORS SHAPE CULTURE.</p>
                            <p className=" text-md-end pb-4 feature-middle-heading" >2 in 3 twitter users agree that creators change and shape culture </p>
                        </div>
                        <div className="tags d-flex pb-2 ">
                            <p className="tag1 fw-bold px-5 mx-md-3 py-1 rounded-pill shadow">#HiArmy</p>
                            <p className="tag2 fw-bold px-5 py-1 rounded-pill shadow">#everydays</p>
                        </div>
                        <div className=" tags d-flex pb-2">
                            <p className="tag3 fw-bold px-5 py-1  rounded-pill shadow">#teamtrees</p>
                            <p className="tag4 fw-bold px-5 py-1 rounded-pill shadow">#BTS</p>
                        </div>
                        <div className="tags">
                            <p className="tags tag5 fw-bold px-5 py-1 rounded-pill shadow">#botarmy</p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Feature;