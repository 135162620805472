import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Form } from 'react-bootstrap'
import searchLogo from './images/search_24px.png'
import FooterLogo from './images/footer-logo.png'

const Footer = () => {
    return (
        <div className="Footer">
            <Container>
                <p className="footer-heading text-center"> With #TweetsFromHome, express your love <br /> for your favourite creator.</p>
                <p className="footer-subheading text-center">
                    Choose the top tweets that you absolutely admire to create your version of their #TweetsFromHome wall and simply share on Twitter.
                </p>
                <div className="position-relative mb-5 d-flex justify-content-center">
                    <Form.Control size="lg" type="text" placeholder="Search your favourite creator" className="footer-input mt-4 rounded border-none shadow " />
                    <img src={searchLogo} alt="searchLogo" className="position-absolute footerSearch " />
                </div>
                <div className="mb-5 d-flex justify-content-center">
                    <a href="/" className=" header-cta fw-bold rounded-pill text-decoration-none py-3 " > Sign in to continue</a>
                </div>
                <div class="social-footer d-flex justify-content-around ">
                    <img src={FooterLogo} alt="FooterLogo" />
                    <p class=" d-flex justify-content-around align-items-center early-access">Get early access for Bread </p>
                    <div className="terms">
                        <p className="">Privacy Policy </p>
                        <p>Terms of use </p>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Footer;
