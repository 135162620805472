import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from './images/logo.svg'
import hamburger from './images/hamburger.png'
import headerImg from './images/header.png'
import headerBg from './images/Rectangle 102.png'
import searchLogo from './images/search_24px.png'
import { Navbar, Nav, Container, Row, Col, Form } from 'react-bootstrap'

function Header() {
  return (
    <div className="Header">
      <Navbar collapseOnSelect expand="lg" bg="white" variant="white">
        <Container>
          <Navbar.Brand href="#home"><img src={logo} alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav border-0 " >
            <img src={hamburger} alt="hamburger" />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className="sign-in  fw-bold ">Sign In</Nav.Link>
              <Nav.Link href="#home" className="fw-bold   rounded-pill home-btn">Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* header */}
      <img src={headerBg} alt="header bg" className="headerbg position-absolute " />
      <Container className="mobile-header">
        <Row>
          <Col className="d-sm-flex align-items-center justify-content-between ">
            <div>
              <p className="main-heading">Become a true <span>SUPERFAN</span></p>
              <p className="sub-heading">Get one step closer to your favourite creator that inspired you in 2020 while you were at home.Simply create their #TweetsFromHome wall and share on Twitter.</p>
              <div className="header-input position-relative mb-5">
                <Form.Control size="lg" type="text" placeholder="Search your favourite creator" className="search-input mt-4 rounded border-none shadow " />
                <img src={searchLogo} alt="searchLogo" className="search-logo position-absolute  " />
              </div>
              <a href="/" className=" header-cta fw-bold rounded-pill text-decoration-none py-3" > View tweets from home</a>
            </div>
          </Col>
          <Col>
            <img src={headerImg} alt="headerImg" className="w-100 mt-5" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
