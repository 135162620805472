import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './Header'
import Feature from './Feature'
import Footer from './Footer'

ReactDOM.render(
  <React.StrictMode>
    <Header />
    <Feature />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

